    import React, { useState, useEffect } from 'react';
    import axios from 'axios';

    function App() {
        
        const queryParameters = new URLSearchParams(window.location.search)
        const orderId = queryParameters.get("id");
        const [order, setOrder]=useState([]);
        const [preorder, setpreOrder]=useState({});

        const fetchDatas = async () => {
          const orderData = {orderId: orderId}
        try {
            const response = await axios.put('https://api.arivomkadai.com/offlineorders/', orderData);
            const order_ids = response.data.data.filter(item => item.order_id === orderId);
            setOrder(order_ids);
            //console.log("orders:", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        };

        const fetchpreDatas = async () => {
        try {
            const response = await axios.get('https://api.arivomkadai.com/preorder_orders/');
            const preorder_ids = response.data.data.filter(item => item.order_id === orderId);
            setpreOrder(preorder_ids);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        };

        useEffect(() => {
        fetchDatas();
        fetchpreDatas();
        }, []);
        
        //console.log(order);

        const handlePrint = () => {
            // Create a new window for printing
            const printWindow = window.open('', '', 'width=800,height=600');
          
            // Check if the window opened successfully
            if (!printWindow) {
              console.error('Failed to open print window.');
              return;
            }
          
            // Add styles and content to the new window
            printWindow.document.write('<html><head><title>Print</title>');
            printWindow.document.write('<style>body { font-family: Arial, sans-serif; margin: 20px; }</style>'); // Optional: Add styles
            printWindow.document.write('</head><body>');
          
            // Get the content to print
            const printContent = document.getElementById('printable-content').innerHTML;
            printWindow.document.write(printContent);
          
            printWindow.document.write('</body></html>');
            printWindow.document.close(); // Necessary for IE >= 10
          
            // Ensure the content is fully loaded before calling print
            setTimeout(() => {
              printWindow.focus(); // Necessary for IE >= 10
              printWindow.print();
              printWindow.close(); // Optionally close the window after printing
              window.close();
            }, 500); // Adjust the delay as needed
          };
          
          
          const closePrint = (printWindow) => {
            setTimeout(() => {
              printWindow.close();
            }, 2000);
          };


        return (
        <>
        {order && order[0] &&  <>
        <div id="printable-content">
        <div  style={{padding:'5px', maxWidth: '364px', maxHeight: '530px', minWidth: '364px', minHeight: '530px', background: '#fff', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', marginLeft:'auto', marginRight:'auto', marginTop: '5px'}}>
          <div style={{ textAlign:'center', minWidth: '352px', minHeight: '526px', border: '3px solid #000', borderRadius: '10px', overflow: 'hidden'}} >
          <img style={{margin: '3px'}} src="logo.png" alt="logo" width="80%" />
            
            <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >5D, CHITHRA NAGAR, SARAVANAMPATTI, CBE - 641035</p>
            </div>

            <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >CUSTOMER CARE NUMBER :   +91 81229 30840 <br></br> G-Pay : 9043842020</p>
            </div>

            {/* <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >ORDER NUMBER : {order[0].order_id} <br />Order Value : Rs. {Number( order[0].grand_total ).toFixed(2)}</p>
            </div> */}
          
            {order.length > 0 && (
            <div style={{ padding: '0px 20px', marginBottom: '10px', textAlign: 'left'}}>
                <h6 style={{fontWeight: 'bold', color: '#000', fontSize: '20px', paddingTop:'5px'}} >{order[0].name}</h6>
                <p style={{color: '#000', fontSize: '17px', fontWeight: 'bold', marginTop: '-10px'}}>
                    {order[0].buiulding},  {order[0].street}<br />
                    {order[0].city} , {order[0].state} - {order[0].pincode}<br />
                    Landmark : {order[0].landmark}
                </p>
                <p style={{color: '#000', fontSize: '18px', fontWeight: 'bold', marginTop: '-8px'}}>
                Contact : {order[0].number}
                </p>
                <hr style={{color: '#000', height: '1px', background: '#000'}}></hr>
                
                <p style={{fontSize: '14px', color: '#000', marginTop: '10px', fontWeight: 'bold'}}> Order Details ( {order[0].count} )</p>
                {order && order[0]?.orderData ? (
                order[0].orderData.map((item, index) => (
                <p style={{marginTop: '-5px', fontSize: '12px', color: '#000', fontWeight: 'bold'}}>{index+1}. {item.product} x {item.quantity}</p>
                ))
                ) : (
                <>No order data available</>
                )}
            
            </div>
            )}
            
            </div>
       
        </div>
       
        </div>
        </> }
        <div align="center">
        <button class="btn btn-primary" onClick={handlePrint}>Print</button>
        </div>
        
        </>
        );
    }
    export default App;